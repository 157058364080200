import React, { useState } from 'react';
import { MenuOutlined, Close } from '@material-ui/icons';
import { Link, NavLink } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectIsAuth } from '../../redux/slices/auth';
import Button from '@mui/material/Button';
import style from './Menu.module.scss';
import styles from './Header.module.scss';
import { Icons } from '../Icons/Icons';

export const Header = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);

  const onClickLogout = () => {
    if (window.confirm('Вы действительно хотите выйти?')) {
      dispatch(logout());
      window.localStorage.removeItem('token');
    }
  };
  const [active, setActive] = useState(false);
  const [ref, setRef] = useState('');

  const showMenu = (event) => {
    setActive(!active);
    console.log(String(event.target));
    if (String(event.target).includes('all-books') || String(event.target).includes('all-posts')) {
      setRef(true);
    } else {
      setRef(false);
    }
  };

  return (
    <>
      <div className={style.menu}>
        <div className={style.logo}>
          <NavLink to="/">
            <img src={require('../../images/Header/logo.png')} alt="" />
          </NavLink>
        </div>
        <nav className={active ? `${style.slider} ${style.active}` : `${style.slider}`}>
          <ul>
            <div className={style.closed}>
              <Close className={style.close} onClick={showMenu} />
            </div>
            {!ref ? (
              <>
                <li>
                  <LinkScroll
                    to="monthBook"
                    onClick={showMenu}
                    className={style.test}
                    spy={false}
                    smooth={true}
                    offset={0}
                    duration={500}>
                    книга місяця
                  </LinkScroll>
                </li>
                <li className={style.test}>
                  <LinkScroll
                    to="readBox"
                    onClick={showMenu}
                    className={style.test}
                    spy={false}
                    smooth={true}
                    offset={0}
                    duration={500}>
                    клубний бокс
                  </LinkScroll>
                </li>
              </>
            ) : (
              <li>
                <Link to="/" onClick={showMenu} className={style.items}>
                  головна сторінка
                </Link>
              </li>
            )}
            <li>
              <Link to="/all-books" onClick={showMenu} className={style.items}>
                що ми читаємо?
              </Link>
            </li>
            <li>
              <Link to="/all-posts" onClick={showMenu} className={style.items}>
                статті
              </Link>
            </li>
            <div className={style.icons}>
              <Icons />
            </div>
          </ul>
        </nav>

        <div className={styles.buttons}>
          {isAuth ? (
            <>
              <Link to="/add-post">
                <Button variant="contained">Написать статью</Button>
              </Link>
              <Button onClick={onClickLogout} variant="contained" color="error">
                Выйти
              </Button>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className={style.menuIcon}>
          <MenuOutlined className={style.hamb} onClick={showMenu} />
        </div>
      </div>
    </>
  );
};
