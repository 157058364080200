import { Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { FullPost, Registration, AddPost, Login } from './pages';
import { Home } from './pages/Home';
import React from 'react';
import { fetchAuthMe, selectIsAuth } from './redux/slices/auth';
import { Header } from './components/Header/Header';
import { PostsPage } from './pages/PostPage/PostsPage';
import { AllBooks } from './pages/AllBooks/AllBooks';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { MoreAboutClub } from './pages/MoreAboutClub';
import { OtherQuestions } from './pages/OtherQuestions';

function App() {
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);

  React.useEffect(() => {
    dispatch(fetchAuthMe());
  }, []);

  return (
    <div className="main">
      <Header />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/all-posts" element={<PostsPage />} />
          <Route path="/posts/:id" element={<FullPost />} />
          <Route path="/posts/:id/edit" element={<AddPost />} />
          <Route path="/add-post" element={<AddPost />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Registration />} />
          <Route path="/all-books" element={<AllBooks />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/more-information" element={<MoreAboutClub />} />
          <Route path="/other-questions" element={<OtherQuestions />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
