import * as React from 'react';
import { Link } from 'react-router-dom';

import styles from './LittlePost.module.scss';
import { UserInfo } from '../UserInfo';
import { Chapter } from '../Chapter/Chapter';
import { PostSkeleton } from './Skeleton';

export const LittlePost = ({
  id,
  title,
  createdAt,

  isLoading,

  tags,
}) => {
  if (isLoading) {
    return <PostSkeleton />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.info}>
        <div className={styles.date}>
          <UserInfo additionalText={createdAt} />
        </div>
        <div className={styles.chapter}>
          <Chapter chapter={tags} />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.readMore}>
          <Link className={styles.readMore__link} to={`/posts/${id}`}>
            Читати далі
          </Link>
        </div>
      </div>
    </div>
  );
};
