import React from 'react';
import { Title } from './Title/Title';
import { InfoBlock } from './InfoBlock/InfoBlock';
import { LittleBooks } from './LittleBooks/LittleBooks';
import { MonthBook } from './MonthBook/MonthBook';
import { ReadBooks } from './ReadBooks/ReadBooks';
import { ReadBox } from './ReadBox/ReadBox';
import { Subscription } from './Subscription/Subscription';
import { Footer } from './Footer/Footer';
import { Scroll } from '../../components/Scroll/Scroll';
import { PostsBlock } from './PostBlock/PostBlock';

export const Home = () => {
  return (
    <div>
      <Title />
      <InfoBlock />
      <LittleBooks />
      <MonthBook />
      <ReadBooks />

      <Subscription />
      <ReadBox />
      <PostsBlock />
      <Footer />
      <Scroll />
    </div>
  );
};
