import React from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import styles from './Post.module.scss';
import { Link } from 'react-router-dom';
export const Post = ({
  id,
  title,
  createdAt,

  user,
  viewsCount,
  tags,
  children,
  isFullPost,
  isLoading,
  isEditable,
}) => {
  return (
    !isLoading && (
      <div className={styles.root}>
        <div className={styles.post}>
          <div className={styles.title}>{title}</div>
          <div className={styles.text}>{children}</div>
          <div className={styles.title__footer}>
            <div className={styles.views}>
              <VisibilityOutlinedIcon fontSize="small" />
              <div className={styles.views__count}>{viewsCount}</div>
            </div>
            <div className={styles.button}>
              <Link className={styles.button__link} to="/all-posts">
                повернутись назад
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
