import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import EyeIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CommentIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import styles from './MainPost.module.scss';
import { UserInfo } from '../UserInfo';
import { PostSkeleton } from './Skeleton';
import { fetchRemovePost, fetchTags } from '../../redux/slices/posts';
import { Chapter } from '../Chapter/Chapter';

export const MainPost = ({
  id,
  title,
  createdAt,
  user,
  viewsCount,
  tags,
  children,
  isFullPost,
  isLoading,
  isEditable,
}) => {
  const dispatch = useDispatch();
  if (isLoading) {
    return <PostSkeleton />;
  }

  const onClickRemove = () => {
    if (window.confirm('Вы действительно хотите удалить статью?')) {
      dispatch(fetchRemovePost(id));
    }
  };

  return (
    <div className={styles.root}>
      {isEditable && (
        <div className={styles.editButtons}>
          <Link to={`/posts/${id}/edit`}>
            <IconButton color="primary">
              <EditIcon />
            </IconButton>
          </Link>
          <IconButton onClick={onClickRemove} color="secondary">
            <DeleteIcon />
          </IconButton>
        </div>
      )}

      <div className={styles.info}>
        <div className={styles.date}>
          <UserInfo additionalText={createdAt} />
        </div>
        <div className={styles.chapter}>
          <Chapter chapter={tags} />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.readMore}>
          <div>
            <Link className={styles.readMore__link} to={`/posts/${id}`}>
              Читати далі
            </Link>
          </div>
          <div className={styles.views}>
            <VisibilityOutlinedIcon fontSize="small" />
            <div className={styles.views__count}>{viewsCount}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
