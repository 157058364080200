import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { LittlePost } from '../../../components/LittlePost/LittlePost';
import { fetchPosts } from '../../../redux/slices/posts';

import styles from './PostBlock.module.scss';

export const PostsBlock = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.data);
  const { posts } = useSelector((state) => state.posts);

  const isPostsLoading = posts.status === 'loading';

  React.useEffect(() => {
    dispatch(fetchPosts());
  }, []);

  return (
    <div className="container">
      <div className={styles.root}>
        <div className={styles.title}>
          <div className={styles.title__text}>що нового?</div>
          <div className={styles.title__button}>
            <Link className={styles.button__link} to="/all-posts">
              більше статей
            </Link>
          </div>
        </div>

        <div className={styles.cards}>
          {(isPostsLoading ? [...Array(3)] : posts.items)
            .slice(posts.items.length - 4, posts.items.length)
            .reverse()
            .map((obj, index) =>
              isPostsLoading ? (
                <div key={index} className={styles.items}>
                  <LittlePost key={index} isLoading={true} />
                </div>
              ) : (
                <div key={index} className={styles.items}>
                  <LittlePost
                    key={index}
                    id={obj._id}
                    title={obj.title}
                   
                    tags={obj.tags}
                    user={obj.user}
                    createdAt={obj.createdAt}
                    viewsCount={obj.viewsCount}
                    isEditable={userData?._id === obj.user._id}
                  />
                </div>
              ),
            )}
        </div>
      </div>
    </div>
  );
};
