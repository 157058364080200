import React from 'react';
import { Scroll } from '../../components/Scroll/Scroll';
import styles from './AllBooks.module.scss';
import { Books } from './Books';

const booksDB2022 = [
  { name: 'Січень', id: 'january' },
  { name: 'Лютий', id: 'february' },
  { name: 'Червень', id: 'june' },
  { name: 'Липень', id: 'july1' },
  { name: 'Липень', id: 'july2' },
  { name: 'Серпень', id: 'august1' },
  { name: 'Серпень', id: 'august2' },
  
];

const booksDB2021 = [
  { name: 'Вересень', id: 'september' },
  { name: 'Жовтень', id: 'october' },
  { name: 'Листопад', id: 'november' },
  { name: 'Листопад', id: 'november2' },
];

export const AllBooks = () => {
  return (
    <div className={styles.root}>
      <div className={styles.title}>Протитано у 2022 році</div>
      <div className={styles.root__year}>
        <Books props={booksDB2022} />
      </div>
      <div className={styles.title}>Протитано у 2021 році</div>
      <div className={styles.root__year}>
        <Books props={booksDB2021} />
      </div>
      <Scroll />
    </div>
  );
};
