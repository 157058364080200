import React from 'react';
import styles from './UserInfo.module.scss';

export const UserInfo = ({ additionalText }) => {
  const fullData = additionalText.slice(0, 10).split('-').reverse().join('-');

  let [date, month] = fullData.split('-');

  const filterMonth = (month) => {
    if (month === '01') {
      return 'Січня';
    }
    if (month === '02') {
      return 'Лютого';
    }
    if (month === '03') {
      return 'Березня';
    }
    if (month === '04') {
      return 'Квітня';
    }
    if (month === '05') {
      return 'Травня';
    }
    if (month === '06') {
      return 'Червня';
    }
    if (month === '07') {
      return 'Липня';
    }
    if (month === '08') {
      return 'Серпня';
    }
    if (month === '09') {
      return 'Вересня';
    }
    if (month === '10') {
      return 'Жовтня';
    }
    if (month === '11') {
      return 'Листопада';
    }
    if (month === '12') {
      return 'Грудня';
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.date}>{date}</div>
      <div className={styles.month}>{filterMonth(month)}</div>
    </div>
  );
};
