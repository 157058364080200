import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import './index.scss';

export const Icons = () => {
  return (
    <div className="footer">
      <div className="icon telegram">
        <div className="toolrip">Telegram</div>
        <a href="https://t.me/monthread" target="_blank" rel="noreferrer">
          <span>
            <i>
              <TelegramIcon />
            </i>
          </span>
        </a>
      </div>
      <div className="icon youtube">
        <div className="toolrip">YouTube</div>
        <a
          href="https://www.youtube.com/channel/UCR6c6kK2wvsTw8JlyllyKCw"
          target="_blank"
          rel="noreferrer">
          <span>
            <i>
              <YouTubeIcon />
            </i>
          </span>
        </a>
      </div>
      <div className="icon instagram">
        <div className="toolrip">Instagram</div>
        <a href="https://www.instagram.com/lira.voropaeva/" target="_blank" rel="noreferrer">
          <span>
            <i>
              <InstagramIcon />
            </i>
          </span>
        </a>
      </div>
      <div className="icon facebook">
        <div className="toolrip">Facebook</div>
        <a href="https://www.facebook.com/groups/monthread" target="_blank" rel="noreferrer">
          <span>
            <i>
              <FacebookIcon />
            </i>
          </span>
        </a>
      </div>
    </div>
  );
};
