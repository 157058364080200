import style from './ReadBooks.module.scss';
import { Link } from 'react-router-dom';

export const ReadBooks = () => {
  return (
    <div className="container">
      <div className={style.readBooks}>
        <div className={style.books}>
          <div className={style.books__title}>
            <div className={style.title__text}>прочитано в клубі</div>
            <div>
              <Link className={style.title__buttonMore} to="/all-books">
                більше
              </Link>
            </div>
          </div>

          <div className={style.books__allBooks}>
            <div className={style.book}>
              <div>
                <img src={require('../../../images/ReadBooks/july1.png')} alt="" />
              </div>
              <div>
                <button>липень</button>
              </div>
            </div>
            <div className={style.book}>
              <div>
                <img src={require('../../../images/ReadBooks/july2.png')} alt="" />
              </div>
              <div>
                <button>липень</button>
              </div>
            </div>
            <div className={style.book}>
              <div>
                <img src={require('../../../images/ReadBooks/august1.png')} alt="" />
              </div>
              <div>
                <button>серпень</button>
              </div>
            </div>
            <div className={style.book}>
              <div>
                <img src={require('../../../images/ReadBooks/august2.png')} alt="" />
              </div>
              <div>
                <button>серпень</button>
              </div>
            </div>
          </div>
        </div>
        <div className={style.ad}></div>
      </div>
    </div>
  );
};
