import React, { useState } from 'react';
import styles from './Chapter.module.scss';

export const Chapter = ({ chapter }) => {
  const checkColor = (chapter) => {
    console.log(chapter);
    if (String(chapter) === 'новини') {
      return '#f97f72';
    }
    if (String(chapter) === 'підбірки') {
      return '#ffc249';
    }
    if (String(chapter) === 'розмова') {
      return '#cfe2de';
    }
  };

  console.log(checkColor(chapter));
  return (
    <div className={styles.chapter} style={{ background: `${checkColor(chapter)}` }}>
      {chapter}
    </div>
  );
};
