import React from 'react';
import styles from './AllBooks.module.scss';

export const Books = ({ props }) => {
  return (
    <div className={styles.allBooks}>
      {props.map((item, index) => {
        return (
          <div className={styles.card} key={index}>
            <div className={styles.card__image}>
              <img src={require(`../../images/AllBooks/${item.id}.png`)} alt="" />
            </div>
            <div className={styles.card__text}>{item.name}</div>
          </div>
        );
      })}
    </div>
  );
};
