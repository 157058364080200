import style from "./Title.module.scss";

export const Title = () => {
  return (
    <div className={style.header}>
      <div className="main">
        <div className={style.head__main}>
          <div className={style.head__main__info}>
            <div className={style.head__main__info__text}>
              <p>книжковий клуб анонімних детективів</p>
            </div>
            <div className={style.head__main__info__circles}>
            <img src={require("../../../images/Header/header_circles.png")} alt="" />
            </div>
          </div>
          <div className={style.head__main__photo}>
          <img src={require("../../../images/Header/header_main.png")} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
