import style from "./InfoBlock.module.scss";

export const InfoBlock = () => {
  return (
    <div className="container">
      <div className={style.infoBlock}>
        <div className={style.left__infoBlock}>
          <div className={style.left__title}>
            <p className={style.left__title__first}>Любиш читати детективи?</p>
            <p className={style.left__title__second}>
              Але не маєш з ким обговорити прочитане?
            </p>
          </div>
          <div className={style.leftBlock__text}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora
            enim excepturi earum optio, ad ratione quae sunt assumenda iste.
            Voluptates saepe inventore assumenda repellat. Pariatur dolores
            nesciunt mollitia ab exercitationem?
          </div>
        </div>
        <div className={style.right__infoBlock}>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis odio
            voluptatibus voluptatem aliquid voluptates ab pariatur animi optio
            minima quibusdam at deserunt, exercitationem voluptas iure. Eveniet
            voluptatem excepturi incidunt ad.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae
            inventore distinctio nihil iure aut, voluptate ipsum qui architecto.
            Cupiditate facilis in magni aliquam, officia nesciunt eius omnis
            adipisci ab? Commodi.
          </p>
        </div>
      </div>
    </div>
  );
};
