import React from 'react';
import ScrollToTop from 'react-scroll-to-top';
import pencil from '../../images/pencil.png';

import styles from './Scroll.module.scss';

export const Scroll = () => {
  return (
    <div className={styles.scroll}>
      <ScrollToTop
        smooth
        component={
          <div>
            <img className={styles.pencil} src={pencil} alt="" />
          </div>
        }
      />
    </div>
  );
};
