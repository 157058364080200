import React, { useState } from 'react';
import styles from './Subscription.module.scss';
import axios from 'axios';

export const Subscription = () => {
  const [value, setValue] = useState('');
  const [answer, setAnswer] = useState('');

  const handleClick = () => {
    if (value && value.includes('@')) {
      axios.post(
        `https://script.google.com/macros/s/AKfycbzPZ5aSKV3F5GsMzPpVVtR1srbJtEE7DvJmLeHyvrK76Xp71fcLKRLBUCWcLXP_Z-n2pQ/exec?email=${value}`,
      );

      setValue('');
      setAnswer('Відправлено');
    } else if (!value.includes('@')) {
      setAnswer('Невірно введений email');
    }
  };

  return (
    <div className="container">
      <div className={styles.root}>
        <div className={styles.form}>
          <div className={styles.form__title}>Підпишись на новини клубу</div>
          <div className={styles.text}>
            <input
              type="text"
              placeholder="Введіть ваш email"
              className={styles.form__input}
              value={value}
              onChange={(event) => setValue(event.target.value)}
            />
            <button className={styles.form__button} onClick={handleClick}>
              відправити
            </button>
          </div>
          <div className={styles.answer}>{answer}</div>
        </div>
        <div className={styles.image}>
          <img src={require('../../../images/Subscription/sub.png')} alt="" />
        </div>
      </div>
    </div>
  );
};
