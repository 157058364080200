import style from "./LittleBooks.module.scss";

export const LittleBooks = () => {
  return (
    <div className="main">
        <div className={style.littleBooks}>
          <div className={style.littleBooks__main}>
            <div className={style.book}>
              <div className={style.littleBooks__img}>
                <img src={require("../../../images/LittleBooks/littleBook_1.png")} alt="" />
              </div>
              <div className={style.littleBooks__text}>
                <p>приєднуйся до читання книги місяця</p>
              </div>
            </div>
            <div className={style.book}>
              <div className={style.littleBooks__img}>
                <img src={require("../../../images/LittleBooks/littleBook_2.png")} alt="" />
              </div>
              <div className={style.littleBooks__text}>
              <p>обговорюй книгу з детективами</p>
              </div>
            </div>
            <div className={style.book}>
              <div className={style.littleBooks__img}>
              <img src={require("../../../images/LittleBooks/littleBook_3.png")} alt="" />
              </div>
              <div className={style.littleBooks__text}>
              <p>обирай книгу на наступний місяць</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
