import style from './MonthBook.module.scss';

export const MonthBook = () => {
  return (
    <div className="container">
      <div className={style.monthBook} id="monthBook">
        <div className={style.photo}>
          <div className={style.blot}>
            <img src={require('../../../images/MonthBook/blot.png')} alt="" />
          </div>
          <div className={style.book}>
            <img src={require('../../../images/MonthBook/monthBook.png')} alt="" />
          </div>
        </div>
        <div className={style.info}>
          <div className={style.title}>цього місяця читаємо</div>
          <div className={style.text}>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magni atque delectus nihil
            voluptatibus tenetur a itaque reprehenderit at quae dignissimos earum nostrum dolorum,
            ut veritatis eligendi voluptatum consequuntur provident adipisci!Lorem ipsum, dolor sit
            amet consectetur adipisicing elit. Magni atque delectus nihil voluptatibus tenetur a
            itaque reprehenderit at quae dignissimos earum nostrum dolorum, ut veritatis eligendi
            voluptatum consequuntur provident adipisci!
          </div>
          <div className={style.button}>
            <button to="http://google.ru/">приєднатися до обговорення</button>
          </div>
        </div>
      </div>
    </div>
  );
};
