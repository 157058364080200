import React from 'react';
import { Link } from 'react-router-dom';
import { Icons } from '../../../components/Icons/Icons';
import styles from './Footer.module.scss';

export const Footer = () => {
  return (
    <div className={styles.root}>
      <div className="container">
        <div className={styles.footer}>
          <div className={styles.info}>
            <div className={styles.name}>КНИЖКОВИЙ КЛУБ АНОНІМНИХ ДЕТЕКТИВІВ</div>
            <div className={styles.moreInformation}>
              <Link to="" className={styles.moreInformation__items}>
                Більше про клуб
              </Link>
              <Link to="" className={styles.moreInformation__items}>
                залишилися питання
              </Link>
              <Link to="" className={styles.moreInformation__items}>
                політика конфідиційності
              </Link>
            </div>
            <div className={styles.icons}>
              <div className={styles.icons__text}>приєднуйся до нас:</div>
              <div>
                <Icons />
              </div>
            </div>
          </div>
          <div className={styles.copyright}>(c) 2022, Книжковий клуб анонімних детективів</div>
        </div>
      </div>
    </div>
  );
};
