import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MainPost } from '../../components/MainPost/MainPost';

import { Post } from '../../components/Post';
import { Scroll } from '../../components/Scroll/Scroll';
import { fetchPosts, fetchTags } from '../../redux/slices/posts';
import styles from './PostPage.module.scss';

export const PostsPage = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.data);
  const { posts } = useSelector((state) => state.posts);

  const isPostsLoading = posts.status === 'loading';
  const [category, setCategory] = useState('Всі');
  const [picture, setPicture] = useState(0);

  const onClickCategory = (event) => {
    setCategory(event.target.id);

    if (event.target.id === 'Всі') {
      setPicture(0);
    }
    if (event.target.id === 'новини') {
      setPicture(1);
    }
    if (event.target.id === 'підбірки') {
      setPicture(2);
    }
    if (event.target.id === 'обговорення') {
      setPicture(3);
    }
  };

  React.useEffect(() => {
    dispatch(fetchPosts());
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.categories}>
          <Link className={styles.buttons} to="" onClick={onClickCategory} id="Всі">
            Всі
          </Link>
          <Link className={styles.buttons} to="" onClick={onClickCategory} id="новини">
            Новини
          </Link>
          <Link className={styles.buttons} to="" onClick={onClickCategory} id="підбірки">
            Підбірки
          </Link>
          <Link className={styles.buttons} to="" onClick={onClickCategory} id="обговорення">
            Обговорення
          </Link>
        </div>
      </div>
      <div className={styles.allPosts}>
        {!isPostsLoading && category === 'Всі'
          ? posts.items
              .filter((item) => item)
              .reverse()
              .map((obj, index) => (
                <MainPost
                  key={index * Math.random()}
                  id={obj._id}
                  title={obj.title}
                  user={obj.user}
                  createdAt={obj.createdAt}
                  viewsCount={obj.viewsCount}
                  tags={obj.tags}
                  isEditable={userData?._id === obj.user._id}
                />
              ))
          : posts.items
              .filter((item) => item.tags == category)
              .reverse()
              .map((obj, index) => (
                <MainPost
                  key={index * Math.random()}
                  id={obj._id}
                  title={obj.title}
                  user={obj.user}
                  createdAt={obj.createdAt}
                  viewsCount={obj.viewsCount}
                  tags={obj.tags}
                  isEditable={userData?._id === obj.user._id}
                />
              ))}
      </div>
      <Scroll />
    </div>
  );
};
